/** @jsx jsx */
import { Link } from "gatsby";
import { jsx } from "theme-ui";
import replaceSlashes from "@lekoarts/gatsby-theme-minimal-blog/src/utils/replaceSlashes";
import useSiteMetadata from "@lekoarts/gatsby-theme-minimal-blog/src/hooks/use-site-metadata";
import useMinimalBlogConfig from "@lekoarts/gatsby-theme-minimal-blog/src/hooks/use-minimal-blog-config";

const HeaderTitle = () => {
  const { siteTitle } = useSiteMetadata();
  const { basePath } = useMinimalBlogConfig();

  return (
    <Link
      to={replaceSlashes(`/${basePath}`)}
      aria-label={`${siteTitle} - Back to home`}
      sx={{
        color: `heading`,
        textDecoration: `none`,
        svg: {
          width: [128, 190],
          height: [24, 36]
        },
        g: {
          fill: `text`,
          transitionProperty: `fill`,
          transitionDuration: `0.25s`,
          transitionTimingFunction: `easings.inOutQuad`
        },
        path: {
          transitionProperty: `opacity`,
          transitionDuration: `0.25s`,
          transitionTimingFunction: `easings.inOutQuad`
        },
        ":hover": {
          ".faded": {
            opacity: 0.75,
          }
        }
      }}
    >
      <svg
        width={128}
        height={24}
        viewBox="0 0 190 36"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fill="white" fillRule="evenodd">
          <path d="M182.053.066l-7.283 12.323L167.48.066h-7.838v35.567h7.839v-19.33l2.635 4.21 4.654 8.125 4.654-8.124 2.629-4.2v19.319l7.837-7.99V.065zM149.87 17.24c.002.205-.003.408 0 .61-.003.201.002.404 0 .61.014 4.843-.552 8.452-7.058 8.452-2.505 0-4.127-.537-5.177-1.472l-5.989 6.152c2.229 2.386 5.714 4.04 11.166 4.04 14.594 0 15.15-11.737 15.15-17.783 0-2.973-.14-7.423-2.107-11.128l-6.294 6.467c.261 1.187.313 2.56.308 4.051" />
          <path
            className="faded"
            d="M142.812.066c-14.543 0-15.098 11.804-15.098 17.783 0 3.756.217 9.766 3.932 13.743l5.99-6.152c-1.677-1.492-1.89-4.002-1.88-6.98-.004-.206.001-.409 0-.61.001-.203-.004-.405 0-.61-.016-4.845.55-8.453 7.056-8.453 4.504 0 6.16 1.73 6.749 4.4l6.294-6.466c-1.964-3.7-5.751-6.655-13.043-6.655"
            opacity={0.5}
          />
          <path d="M118.195 35.633V21.712h-14.57v13.92h-7.84V16.618l7.84-7.99v13.085l7.838-7.872h6.732V.066h7.837v27.576zM71.695 13.84V7.971h10.663s3.534.106 3.534 2.976-3.534 2.893-3.534 2.893H71.695zm22.408-2.404C94.119 5.52 89.98.066 83.34.066H63.857v35.567l7.838-7.991v-5.93h8.9l1.466 5.11.842 2.932 6.312-6.436-.61-2.127-.106-.37.026-.003s5.561-3.464 5.578-9.382zM40.021 18.46c-.003-.206.002-.41 0-.61.002-.202-.003-.405 0-.61-.015-4.845.551-8.453 7.057-8.453 2.506 0 4.128.537 5.177 1.471l5.989-6.152C56.016 1.721 52.53.066 47.078.066c-14.594 0-15.15 11.738-15.15 17.783 0 2.973.14 7.423 2.107 11.128l6.294-6.466c-.261-1.188-.312-2.561-.308-4.052" />
          <path
            className="faded"
            d="M47.078 35.633c14.543 0 15.098-11.805 15.098-17.784 0-3.756-.217-9.765-3.932-13.743l-5.989 6.152c1.676 1.493 1.89 4.003 1.88 6.981.003.206-.002.408 0 .61-.002.202.003.405 0 .61.015 4.845-.551 8.453-7.057 8.453-4.504 0-6.16-1.73-6.749-4.401l-6.294 6.466c1.965 3.7 5.752 6.656 13.043 6.656"
            opacity={0.5}
          />
          <path d="M.03 17.027C.342 10.634 4.051 4.531 10.367 1.545 12.305.675 14.812.017 17.986.07c5.692.276 9.291 2.29 12.262 5.722l-5.56 5.45C19.663 5.35 9.105 7.56 8.16 17.027c-.132 8.96 8.931 11.864 14.024 8.935V21.74h-7.923l7.753-7.899h8.233V29.9c-2.71 2.91-6.456 5.357-11.212 5.719C4.279 36.109-.421 23.349.029 17.027" />
        </g>
      </svg>
      {/* <h1 sx={{ my: 0, fontWeight: `medium`, fontSize: [3, 4] }}>{siteTitle}</h1> */}
    </Link>
  );
};

export default HeaderTitle;
